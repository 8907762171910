// import bulmaCalendar from "../utilities/bulma/bulma-calendar"
import bulmaCalendar from "../utilities/bulma/bulma-calendar_6_1_18"
$(document).on(('turbo:load'), () => {
  if ($('#professional_course_start_date_of_event').length) {
    let default_date = new Date();
    let date_val = $("#professional_course_start_date_of_event").val();
    const editing = $('#editing_record')[0];
    if (!date_val) date_val = $("#professional_course_start_date_of_event")[0].getAttribute('value');
    if (!date_val) date_val = default_date;
    if (date_val.constructor.name === 'String') date_val = new Date(date_val);
    let offset = default_date.getTimezoneOffset()
    $("#professional_course_start_date_of_event").val(new Date(date_val + offset));

    const date_selectable = function(year_index, month_index) {
      const current_users_group = document.getElementById('cycle_group_number').dataset.cycleGroupNumber;
      const current_date = new Date(year_index, month_index, 1);
      let calculation_number = 999999
      switch (current_users_group) {
        case '1':
          calculation_number = (current_date.getFullYear() - 1999) % 3;
          break;
        case '2':
          calculation_number = (current_date.getFullYear() - 2001) % 3;
          break;
        case '3':
          calculation_number = (current_date.getFullYear() - 2000) % 3;
          break;
      }
      return calculation_number === 0;
    }

    let options = {
      startDate: date_selectable(date_val.getMonth() > 6 ? date_val.getFullYear() + 1 : date_val.getFullYear(), date_val.getMonth()) ? date_val : null,
      "color": "#000858",
      "dateFormat": "MM/dd/yyyy",
      "showHeader": false, 
      "showButtons": false, 
      "showFooter": false, 
      "showClearButton": false,
      disabledDates: function(date, day) {
        const calendar_date = new Date(day);
        const year_offset = calendar_date.getMonth() > 6;
        if (editing) {
          const system_date = new Date(editing.dataset.courseStartDate);
          if (system_date.getFullYear() === calendar_date.getFullYear() + (year_offset ? 1 : 0)) {
            return !date_selectable(year_offset ? calendar_date.getFullYear() + 1 : calendar_date.getFullYear(), calendar_date.getMonth());
          } else {
            return true;
          }
        } else {
          return !date_selectable(year_offset ? calendar_date.getFullYear() + 1 : calendar_date.getFullYear(), calendar_date.getMonth());
        }
    }}
    const calendars = bulmaCalendar.attach('[type="date"]', options);

    var element = calendars[0];
    if (element) {
      $(".datetimepicker-clear-button").hide();
      element.on('select', function(datepicker) {
        $("#professional_course_start_date_of_event").trigger("change");
        $("#professional_course_start_date_of_event").val(datepicker.data.value());
      });
    }
  }
})